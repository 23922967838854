/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    p: "p",
    a: "a",
    h3: "h3",
    strong: "strong",
    em: "em"
  }, _provideComponents(), props.components), {Boss, Trait, Warning, Skill, Item, Condition, Effect, Boon, SpecialActionKey, Attribute, Specialization} = _components;
  if (!Attribute) _missingMdxReference("Attribute", true);
  if (!Boon) _missingMdxReference("Boon", true);
  if (!Boss) _missingMdxReference("Boss", true);
  if (!Condition) _missingMdxReference("Condition", true);
  if (!Effect) _missingMdxReference("Effect", true);
  if (!Item) _missingMdxReference("Item", true);
  if (!Skill) _missingMdxReference("Skill", true);
  if (!SpecialActionKey) _missingMdxReference("SpecialActionKey", true);
  if (!Specialization) _missingMdxReference("Specialization", true);
  if (!Trait) _missingMdxReference("Trait", true);
  if (!Warning) _missingMdxReference("Warning", true);
  return React.createElement(React.Fragment, null, React.createElement(Boss, {
    name: "mama",
    video: "GbaPfzA13uI",
    videoCreator: "Jetrell [dT]",
    foodId: "43360",
    utilityId: "50082",
    heal: "bloodreckoning",
    utility1Id: "29941",
    utility2: "outrage",
    utility3: "bullscharge",
    eliteId: "30343",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Axe",
    weapon1MainSigil1: "Impact",
    weapon1MainInfusion1Id: "37131",
    weapon1OffAffix: "berserker",
    weapon1OffType: "Axe",
    weapon1OffSigil: "Force",
    weapon1OffInfusionId: "37131",
    weapon2OffAffix: "Berserker",
    weapon2OffType: "Mace",
    weapon2OffSigil: "Paralyzation",
    weapon2OffInfusionId: "37131",
    video: "GbaPfzA13uI",
    videoCreator: "Jetrell [dT]",
    foodId: "43360",
    utilityId: "50082",
    utility1Id: "29941",
    eliteId: "30343",
    weapon1MainInfusion1Id: "37131",
    weapon1OffInfusionId: "37131",
    weapon2OffInfusionId: "37131",
    weapon1MainId: "46759",
    weapon1MainSigil1Id: "24868",
    weapon1OffId: "46759",
    weapon1OffSigilId: "24615",
    weapon2OffId: "46766",
    weapon2OffSigilId: "24639",
    healId: "30189",
    utility2Id: "30258",
    utility3Id: "14516"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Play ", React.createElement(Trait, {
    name: "Smashbrawler",
    id: "2049"
  }), "!"), "\n"), React.createElement(Warning, null, React.createElement(_components.p, null, "This guide assumes you kill MAMA in under 50 seconds (p1 break). The CC-distribution greatly changes depending on the speed the party is going."), React.createElement(_components.p, null, "If there is any doubt about the kill time, go with the PuG version. The following guide assumes you are following the proposed ", React.createElement(_components.a, {
    href: "/guides/cc-distribution"
  }, "CC-Distribution"), "."))), "\n", React.createElement(_components.p, null, "There is an invisible hitbox in the center of the arena allowing you to gain enough adrenaline for one ", React.createElement(Skill, {
    name: "decapitate",
    id: "30851"
  }), " with only two critical hits!"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Prestacking")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Precast as in the video."), "\n", React.createElement(_components.li, null, "Get in combat as fast as possible to fill up adrenaline: Use ", React.createElement(Skill, {
    name: "Cycloneaxe",
    id: "14421"
  }), " against the invisible hitbox and weapon swap to mace."), "\n", React.createElement(_components.li, null, "Shortly before Mama becomes vulnerable, use ", React.createElement(Skill, {
    name: "berserk",
    id: "30185"
  }), " and ", React.createElement(Skill, {
    name: "Bullscharge",
    id: "14516"
  }), " for ", React.createElement(Trait, {
    name: "peakperformance",
    id: "1444"
  }), "."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "1st Phase")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Tremor",
    id: "14415"
  }), " to CC."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "headbutt",
    id: "30343"
  }), " and ", React.createElement(Skill, {
    name: "outrage",
    id: "30258"
  }), " to CC quickly."), "\n", React.createElement(_components.li, null, "Swap weapons."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "decapitate",
    id: "30851"
  }), ", ", React.createElement(Skill, {
    name: "bloodreckoning",
    id: "30189"
  }), ", ", React.createElement(Skill, {
    name: "decapitate",
    id: "30851"
  }), "."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "1st Add")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "CC with ", React.createElement(Skill, {
    name: "Wildblow",
    id: "29941"
  }), "."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "2nd Phase")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "outrage",
    id: "30258"
  }), " as soon as it's off cd."), "\n", React.createElement(_components.li, null, "Normal rotation according to the guide. Take advantage off the invisible hitbox to get more decapitates in."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "2nd Add")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Swap to Mace to trigger ", React.createElement(Item, {
    name: "severance",
    id: "84505"
  })), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Headbutt",
    id: "30343"
  }), ", ", React.createElement(Skill, {
    name: "outrage",
    id: "30258"
  }), ". In case your party goes too fast ", React.createElement(Skill, {
    name: "Headbutt",
    id: "30343"
  }), " won't be off cooldown. In that case you need a ", React.createElement(Item, {
    name: "frenzy",
    id: "82876"
  }), " on your mainhand axe."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "crushing blow",
    id: "14518"
  }), " for ", React.createElement(Condition, {
    name: "Vulnerability"
  }), " application."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "3rd Phase")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Stay on Mace to trigger ", React.createElement(Item, {
    name: "severance",
    id: "84505"
  }), " again."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "bullscharge",
    id: "14516"
  }), ", ", React.createElement(Skill, {
    name: "tremor",
    id: "14415"
  }), "."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "3rd Add")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Wildblow",
    id: "29941"
  }), "\n"), "\n", React.createElement(_components.li, null, "Swap to Mace and use ", React.createElement(Skill, {
    name: "crushing blow",
    id: "14518"
  }), " for ", React.createElement(Condition, {
    name: "Vulnerability"
  }), " application."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "4th Phase")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "headbutt",
    id: "30343"
  }), " and ", React.createElement(Skill, {
    name: "outrage",
    id: "30258"
  }), " to CC quickly."), "\n", React.createElement(_components.li, null, "Swap to Axe/Axe as soon as you are done with CCing and DPS."), "\n"), "\n", React.createElement(Boss, {
    name: "siax",
    video: "Rle5n7NIye4",
    timestamp: "5",
    videoCreator: "Zyp [dT]",
    foodId: "43360",
    utilityId: "50082",
    healId: "30189",
    utility1Id: "14354",
    utility2Id: "30258",
    utility3Id: "14404",
    eliteId: "30343",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Axe",
    weapon1MainSigil1: "Impact",
    weapon1MainInfusion1Id: "37131",
    weapon1OffAffix: "Berserker",
    weapon1OffType: "Axe",
    weapon1OffSigil: "Serpentslaying",
    weapon1OffInfusionId: "37131",
    weapon2MainAffix: "Berserker",
    weapon2MainType: "Rifle",
    weapon2MainSigil1: "Force",
    weapon2MainSigil2: "Serpentslaying",
    weapon2MainInfusion1Id: "37131",
    weapon2MainInfusion2Id: "37131",
    video: "Rle5n7NIye4",
    videoCreator: "Zyp [dT]",
    foodId: "43360",
    utilityId: "50082",
    healId: "30189",
    utility1Id: "14354",
    utility2Id: "30258",
    utility3Id: "14404",
    eliteId: "30343",
    weapon1MainInfusion1Id: "37131",
    weapon1OffInfusionId: "37131",
    weapon2MainInfusion1Id: "37131",
    weapon2MainInfusion2Id: "37131",
    weapon1MainId: "46759",
    weapon1MainSigil1Id: "24868",
    weapon1OffId: "46759",
    weapon1OffSigilId: "24658",
    weapon2MainId: "46768",
    weapon2MainSigil1Id: "24615",
    weapon2MainSigil2Id: "24658"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "In organized teams a rifle can kill the adds very efficiently!"), "\n", React.createElement(_components.li, null, "Play ", React.createElement(Trait, {
    name: "Smashbrawler",
    id: "2049"
  }), "!"), "\n"), React.createElement(Warning, null, React.createElement(_components.p, null, "This build only works if you kill Siax in less than 55 seconds. If there is any doubt about the kill time, go with the PuG version."))), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "1st Phase")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "As soon as you get in combat use ", React.createElement(Skill, {
    name: "throwbolas",
    id: "14354"
  }), " and ", React.createElement(Skill, {
    name: "whirlingaxe",
    id: "14399"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "decapitate",
    id: "30851"
  }), ", ", React.createElement(Skill, {
    name: "bloodreckoning",
    id: "30189"
  }), ", ", React.createElement(Skill, {
    name: "decapitate",
    id: "30851"
  }), "."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "headbutt",
    id: "30343"
  }), " and ", React.createElement(Skill, {
    name: "outrage",
    id: "30258"
  }), " to CC quickly."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "1st Add")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Swap to rifle, start with ", React.createElement(Skill, {
    name: "explosiveshell",
    id: "14472"
  }), " to apply ", React.createElement(Condition, {
    name: "Vulnerability"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Volley",
    id: "14416"
  }), " for big damage."), "\n", React.createElement(_components.li, null, "Finish the add with ", React.createElement(Skill, {
    name: "gunflame",
    id: "29644"
  }), " and ", React.createElement(Skill, {
    name: "brutalshot",
    id: "34296"
  }), "."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "2nd Phase")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Don't forget to apply ", React.createElement(Trait, {
    name: "peakperformance",
    id: "1444"
  }), " with ", React.createElement(Skill, {
    name: "throwbolas",
    id: "14354"
  }), "."), "\n", React.createElement(_components.li, null, "Do what a warrior does: smash ", React.createElement(Skill, {
    name: "decapitate",
    id: "30851"
  }), "."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "2nd Add")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Swap to rifle, start with ", React.createElement(Skill, {
    name: "explosiveshell",
    id: "14472"
  }), " to apply ", React.createElement(Condition, {
    name: "Vulnerability"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Volley",
    id: "14416"
  }), " for big damage."), "\n", React.createElement(_components.li, null, "Finish the add with ", React.createElement(Skill, {
    name: "gunflame",
    id: "29644"
  }), " and ", React.createElement(Skill, {
    name: "brutalshot",
    id: "34296"
  }), "."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "3rd Phase")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Don't forget to apply ", React.createElement(Trait, {
    name: "peakperformance",
    id: "1444"
  }), " with ", React.createElement(Skill, {
    name: "throwbolas",
    id: "14354"
  }), "."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "headbutt",
    id: "30343"
  }), " and ", React.createElement(Skill, {
    name: "outrage",
    id: "30258"
  }), " first thing as your ", React.createElement(Skill, {
    name: "Berserk",
    id: "30185"
  }), " is about to run out at that point."), "\n", React.createElement(_components.li, null, "Do what a warrior does: smash ", React.createElement(Skill, {
    name: "decapitate",
    id: "30851"
  }), "."), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Annotation:")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Depending on your kill time you might want to use the ", React.createElement(Skill, {
    name: "headbutt",
    id: "30343"
  }), " - ", React.createElement(Skill, {
    name: "outrage",
    id: "30258"
  }), " combo earlier."), "\n"), "\n", React.createElement(Boss, {
    name: "ensolyss",
    video: "NbJl4FQFTaI",
    timestamp: "63",
    videoCreator: "Roach [dT]",
    foodId: "91805",
    utilityId: "50082",
    healId: "14401",
    utility1Id: "14405",
    utility2Id: "14407",
    utility3Id: "14516",
    eliteId: "30343",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Axe",
    weapon1MainSigil1: "Impact",
    weapon1MainInfusion1Id: "37131",
    weapon1OffAffix: "Berserker",
    weapon1OffType: "Axe",
    weapon1OffSigil: "Serpentslaying",
    weapon1OffInfusionId: "37131",
    weapon2MainAffix: "Berserker",
    weapon2MainType: "Axe",
    weapon2MainSigil1: "Severance",
    weapon2OffType: "Torch",
    weapon2OffAffix: "Berserker",
    weapon2OffSigil: "Paralyzation",
    weapon2MainInfusion1Id: "37131",
    weapon2OffInfusionId: "37131",
    video: "NbJl4FQFTaI",
    videoCreator: "Roach [dT]",
    foodId: "91805",
    utilityId: "50082",
    healId: "14401",
    utility1Id: "14405",
    utility2Id: "14407",
    utility3Id: "14516",
    eliteId: "30343",
    weapon1MainInfusion1Id: "37131",
    weapon1OffInfusionId: "37131",
    weapon2MainInfusion1Id: "37131",
    weapon2OffInfusionId: "37131",
    weapon1MainId: "46759",
    weapon1MainSigil1Id: "24868",
    weapon1OffId: "46759",
    weapon1OffSigilId: "24658",
    weapon2MainId: "46759",
    weapon2MainSigil1Id: "84505",
    weapon2OffId: "46775",
    weapon2OffSigilId: "24639"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Take ", React.createElement(Trait, {
    name: "savageinstinct",
    id: "1977"
  }), "!"), "\n", React.createElement(_components.li, null, "In slow teams (PuGs) bring ", React.createElement(Trait, {
    name: "smashbrawler",
    id: "2049"
  }), " and ", React.createElement(Skill, {
    name: "outrage",
    id: "30258"
  }), "."), "\n", React.createElement(_components.li, null, "In fast teams bring ", React.createElement(Skill, {
    name: "bullscharge",
    id: "14516"
  }), " over ", React.createElement(Skill, {
    name: "signetofmight",
    id: "14404"
  }), "."), "\n")), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "1st Phase")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Headbutt",
    id: "30343"
  }), " start to cast a few milliseconds before he looses ", React.createElement(Effect, {
    name: "Invulnerability"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Berserk",
    id: "30185"
  }), "."), "\n", React.createElement(_components.li, null, "Swap weapons."), "\n", React.createElement(_components.li, null, "Smash ", React.createElement(Skill, {
    name: "Decapitate",
    id: "30851"
  }), " hard.\nPick up your banners - in case you did not precast them - after you phased Ensolyss!"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "66% and 33%")), "\n", React.createElement(_components.p, null, "Swap to your off set (axe/torch) and cap the circle. After you meet in the middle, continue with:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "Flames of war",
    id: "29940"
  }), " and blast 6 stacks of ", React.createElement(Boon, {
    name: "Might"
  }), " with ", React.createElement(Skill, {
    name: "Banner of Strength",
    id: "14405"
  }), " and ", React.createElement(Skill, {
    name: "Banner of Discipline",
    id: "14407"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Mending",
    id: "14401"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "cycloneaxe",
    id: "14421"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Headbutt",
    id: "30343"
  }), " to trigger ", React.createElement(Item, {
    name: "severance",
    id: "84505"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Berserk",
    id: "30185"
  }), "."), "\n", React.createElement(_components.li, null, "Swap weapons."), "\n", React.createElement(_components.li, null, "Smash ", React.createElement(Skill, {
    name: "Decapitate",
    id: "30851"
  }), " hard."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "bullscharge",
    id: "14516"
  }), " before ", React.createElement(Skill, {
    name: "Whirlingaxe",
    id: "14399"
  }), " to refresh ", React.createElement(Trait, {
    name: "peakperformance",
    id: "1444"
  }), "."), "\n"), "\n", React.createElement(Boss, {
    name: "skorvald",
    foodId: "91805",
    utilityId: "73191",
    healId: "14401",
    utility1Id: "14405",
    utility2Id: "14407",
    utility3Id: "14404",
    eliteId: "30343",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Axe",
    weapon1MainSigil1: "Impact",
    weapon1MainInfusion1Id: "37131",
    weapon1OffAffix: "Berserker",
    weapon1OffType: "Axe",
    weapon1OffSigil: "Force",
    weapon1OffInfusionId: "37131",
    weapon2MainAffix: "Berserker",
    weapon2MainType: "Axe",
    weapon2MainSigil1: "Elementalslaying",
    weapon2OffType: "Warhorn",
    weapon2OffAffix: "Berserker",
    weapon2OffSigil: "Paralyzation",
    weapon2MainInfusion1Id: "37131",
    weapon2OffInfusionId: "37131",
    foodId: "91805",
    utilityId: "73191",
    healId: "14401",
    utility1Id: "14405",
    utility2Id: "14407",
    utility3Id: "14404",
    eliteId: "30343",
    weapon1MainInfusion1Id: "37131",
    weapon1OffInfusionId: "37131",
    weapon2MainInfusion1Id: "37131",
    weapon2OffInfusionId: "37131",
    weapon1MainId: "46759",
    weapon1MainSigil1Id: "24868",
    weapon1OffId: "46759",
    weapon1OffSigilId: "24615",
    weapon2MainId: "46759",
    weapon2MainSigil1Id: "24661",
    weapon2OffId: "46777",
    weapon2OffSigilId: "24639"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Play ", React.createElement(Trait, {
    name: "savageinstinct",
    id: "1977"
  }), "!"), "\n", React.createElement(_components.li, null, "4-way split: you solo the first anomaly at 33% with a ", React.createElement(Item, {
    id: "78978",
    id: "78978"
  })), "\n", React.createElement(_components.li, null, "Precast ", React.createElement(Skill, {
    name: "flamesofwar",
    id: "29940"
  }), " before the boss starts and swap immediately to warhorn."), "\n", React.createElement(_components.li, null, "Optional: you can take ", React.createElement(Skill, {
    name: "signetoffury",
    id: "14410"
  }), " instead of ", React.createElement(Skill, {
    name: "signetofmight",
    id: "14404"
  }), " for extra burst."), "\n")), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "100%-66%")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Headbutt",
    id: "30343"
  }), " and ", React.createElement(Skill, {
    name: "Berserk",
    id: "30185"
  }), "."), "\n", React.createElement(_components.li, null, "Swap weapons."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "decapitate",
    id: "30851"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "cycloneaxe",
    id: "14421"
  }), ", ", React.createElement(Skill, {
    name: "dualstrike",
    id: "14418"
  }), " and ", React.createElement(Skill, {
    name: "decapitate",
    id: "30851"
  }), "."), "\n", React.createElement(_components.li, null, "Finish the phase with ", React.createElement(Skill, {
    name: "whirlingaxe",
    id: "14399"
  }), "."), "\n", React.createElement(_components.li, null, "Leave your banners behind."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "Islands")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Open a ", React.createElement(Item, {
    id: "78978",
    id: "78978"
  }), " in the middle of the first Island after killing the add."), "\n", React.createElement(_components.li, null, "Swap to your warhorn and blast a fire field for ", React.createElement(Boon, {
    name: "Might"
  }), " at 2nd and 4th island."), "\n", React.createElement(_components.li, null, "Don't use heavy cooldowns after the 3rd island."), "\n", React.createElement(_components.li, null, "On the last island after the add finishes the jump use ", React.createElement(Skill, {
    name: "Mending",
    id: "14401"
  }), " and open ", React.createElement(Skill, {
    name: "Berserk",
    id: "30185"
  }), " already."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "66%-33%")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Follow the standard fractal rotation."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "Solo Island 1")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Open your ", React.createElement(Item, {
    id: "78978",
    id: "78978"
  }), " and take it."), "\n", React.createElement(_components.li, null, "Kill your add quickly. You need at least one ", React.createElement(Skill, {
    name: "decapitate",
    id: "30851"
  }), " to solo the add before your portal runs out."), "\n", React.createElement(_components.li, null, "Take it back, immediately recast your banners"), "\n", React.createElement(_components.li, null, "Cast ", React.createElement(Skill, {
    name: "Mending",
    id: "14401"
  }), " as soon as you see that the others are about to be done with their islands."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "33%-0%")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Follow the standard fractal rotation."), "\n"), "\n", React.createElement(Boss, {
    name: "artsariiv",
    foodId: "91805",
    utilityId: "73191",
    healId: "14401",
    utility1Id: "14405",
    utility2Id: "14407",
    utility3: "signetoffury",
    elite: "battlestandard",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Axe",
    weapon1MainSigil1: "Force",
    weapon1MainInfusion1Id: "37131",
    weapon1OffAffix: "Berserker",
    weapon1OffType: "Axe",
    weapon1OffSigil: "Impact",
    weapon1OffInfusionId: "37131",
    weapon2MainAffix: "Berserker",
    weapon2MainType: "Sword",
    weapon2MainSigil1: "Severance",
    weapon2MainInfusion1Id: "37131",
    weapon2OffAffix: "Berserker",
    weapon2OffType: "Warhorn",
    weapon2OffSigil: "Paralyzation",
    weapon2OffInfusionId: "37131",
    foodId: "91805",
    utilityId: "73191",
    healId: "14401",
    utility1Id: "14405",
    utility2Id: "14407",
    weapon1MainInfusion1Id: "37131",
    weapon1OffInfusionId: "37131",
    weapon2MainInfusion1Id: "37131",
    weapon2OffInfusionId: "37131",
    weapon1MainId: "46759",
    weapon1MainSigil1Id: "24615",
    weapon1OffId: "46759",
    weapon1OffSigilId: "24868",
    weapon2MainId: "46774",
    weapon2MainSigil1Id: "84505",
    weapon2OffId: "46777",
    weapon2OffSigilId: "24639",
    utility3Id: "14410",
    eliteId: "14419"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Play ", React.createElement(Trait, {
    name: "smashbrawler",
    id: "2049"
  }), "!"), "\n", React.createElement(_components.li, null, "Mid-strategy: the strategy is focused around phasing the boss in the middle before she moves."), "\n")), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "100%-66% in mid")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Precast banners before taking the ", React.createElement(_components.em, null, "Mistlock Singularity"), "."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "mending",
    id: "14401"
  }), " just before the fight starts."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "signetoffury",
    id: "14410"
  }), " as soon as she looses ", React.createElement(Effect, {
    name: "invulnerability"
  }), " and use ", React.createElement(Skill, {
    name: "berserk",
    id: "30185"
  }), "."), "\n", React.createElement(_components.li, null, "Cast ", React.createElement(Skill, {
    name: "whirlingaxe",
    id: "14399"
  }), " and walk through the boss."), "\n", React.createElement(_components.li, null, "Continue with your normal rotation. Don't get knocked!"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "Add Phases")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Swap to Sword/Warhorn, and CC your add quickly."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "savageleap",
    id: "14366"
  }), " to get around the arena quickly."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(SpecialActionKey, {
    name: "hypernovalaunch"
  }), " to get back into the middle."), "\n", React.createElement(_components.li, null, "Precast ", React.createElement(Skill, {
    name: "Mending",
    id: "14401"
  }), " and ", React.createElement(Skill, {
    name: "charge",
    profession: "warrior",
    id: "14393"
  }), "."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "66%-33%")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Normal rotation, remember to integrate ", React.createElement(Skill, {
    name: "signetoffury",
    id: "14410"
  }), " for the increased ", React.createElement(Attribute, {
    name: "Ferocity"
  }), "."), "\n", React.createElement(_components.li, null, "After the mid burn blast a fire field with your banners in the middle for ", React.createElement(Boon, {
    name: "Might"
  }), "."), "\n", React.createElement(_components.li, null, "Finish the boss on the side."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "33%-0%")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Normal rotation, remember to integrate ", React.createElement(Skill, {
    name: "signetoffury",
    id: "14410"
  }), " for the increased ", React.createElement(Attribute, {
    name: "Ferocity"
  }), "."), "\n", React.createElement(_components.li, null, "Fire everything to kill her in middle."), "\n"), "\n", React.createElement(Boss, {
    name: "arkk",
    video: "CmqdVeGttOI",
    videoCreator: "Roach [dT]",
    foodId: "91805",
    utilityId: "50082",
    healId: "14401",
    utility1Id: "14405",
    utility2Id: "14407",
    utility3Id: "14404",
    eliteId: "14419",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Axe",
    weapon1MainSigil1: "Force",
    weapon1MainInfusion1Id: "37131",
    weapon1OffAffix: "Berserker",
    weapon1OffType: "Axe",
    weapon1OffSigil: "Impact",
    weapon1OffInfusionId: "37131",
    weapon2OffAffix: "Berserker",
    weapon2OffType: "Mace",
    weapon2OffSigil: "Severance",
    weapon2OffInfusionId: "37131",
    video: "CmqdVeGttOI",
    videoCreator: "Roach [dT]",
    foodId: "91805",
    utilityId: "50082",
    healId: "14401",
    utility1Id: "14405",
    utility2Id: "14407",
    utility3Id: "14404",
    eliteId: "14419",
    weapon1MainInfusion1Id: "37131",
    weapon1OffInfusionId: "37131",
    weapon2OffInfusionId: "37131",
    weapon1MainId: "46759",
    weapon1MainSigil1Id: "24615",
    weapon1OffId: "46759",
    weapon1OffSigilId: "24868",
    weapon2OffId: "46766",
    weapon2OffSigilId: "84505"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Play ", React.createElement(Trait, {
    name: "smashbrawler",
    id: "2049"
  }), "!"), "\n")), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Prestacking")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Drop a ", React.createElement(Item, {
    name: "whitemantleportaldevice",
    id: "78978"
  }), " near the ", React.createElement(_components.em, null, "Mistlock Singularity"), " and jump over to Arkk's platform using the ", React.createElement(SpecialActionKey, {
    name: "hypernovalaunch"
  }), "."), "\n", React.createElement(_components.li, null, "Precast ", React.createElement(Skill, {
    name: "Banner of Strength",
    id: "14405"
  }), ", ", React.createElement(Skill, {
    name: "Banner of Discipline",
    id: "14407"
  }), ", ", React.createElement(Skill, {
    name: "Banner of Tactics",
    id: "14408"
  }), " and ", React.createElement(Skill, {
    name: "battlestandard",
    id: "14419"
  }), "."), "\n", React.createElement(_components.li, null, "Take the portal back."), "\n", React.createElement(_components.li, null, "Drop a fire field with ", React.createElement(Skill, {
    name: "Flames of war",
    id: "29940"
  }), "."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "For great justice",
    id: "14403"
  }), " as soon as you see ", React.createElement(Skill, {
    name: "Moa stance",
    id: "45970"
  }), " on your bar."), "\n", React.createElement(_components.li, null, "Blast with ", React.createElement(Skill, {
    name: "Charge",
    profession: "Warrior",
    id: "14393"
  }), " and ", React.createElement(Skill, {
    name: "Call of Valor",
    profession: "Warrior",
    id: "14394"
  }), "."), "\n", React.createElement(_components.li, null, "Shortly before somebody triggers the encounter:", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Precast ", React.createElement(Skill, {
    name: "Flames of war",
    id: "29940"
  }), " for DPS then swap back to mace."), "\n", React.createElement(_components.li, null, "Precast ", React.createElement(Skill, {
    name: "Mending",
    id: "14401"
  }), "."), "\n", React.createElement(_components.li, null, "Take the ", React.createElement(_components.em, null, "Mistlock Singularity"), " to start the fight with maximum adrenaline."), "\n"), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "1st Phase (100%-80%)")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Don't move your camera or character model until the Eye is gone or you will get ", React.createElement(Condition, {
    name: "Fear"
  }), "."), "\n", React.createElement(_components.li, null, "Engage ", React.createElement(Skill, {
    name: "berserk",
    id: "30185"
  }), " right away and start your normal rotation."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "Bloom phases")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Swap to offhand mace."), "\n", React.createElement(_components.li, null, "Dodge into the lightning."), "\n", React.createElement(_components.li, null, "Auto attack the bloom."), "\n", React.createElement(_components.li, null, "Use a ", React.createElement(Skill, {
    name: "Throw axe",
    profession: "warrior",
    id: "14398"
  }), " against the bloom to push it to the tower."), "\n", React.createElement(_components.li, null, "Dodge out."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "mending",
    id: "14401"
  }), " to restore ", React.createElement(Item, {
    name: "scholar",
    id: "24836"
  }), "."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "2nd Phase (80%-70%)")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Save your ", React.createElement(Skill, {
    name: "berserk",
    id: "30185"
  }), " for the 70%-50% burn."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "tremor",
    id: "14415"
  }), " to gain ", React.createElement(Item, {
    name: "severance",
    id: "84505"
  }), "."), "\n", React.createElement(_components.li, null, "Finish up the phase with ", React.createElement(Skill, {
    name: "cycloneaxe",
    id: "14421"
  }), " and ", React.createElement(Skill, {
    name: "dualstrike",
    id: "14418"
  }), "."), "\n", React.createElement(_components.li, null, "If there is time left use ", React.createElement(Skill, {
    name: "whirlingaxe",
    id: "14399"
  }), "."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "Archdiviner")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Blast a fire field that is provided by the ", React.createElement(Specialization, {
    name: "Firebrand"
  }), " or ", React.createElement(Specialization, {
    name: "Weaver"
  }), " with your banners to stack 6 stacks of ", React.createElement(Boon, {
    name: "Might"
  }), "."), "\n", React.createElement(_components.li, null, "Engage ", React.createElement(Skill, {
    name: "berserk",
    id: "30185"
  }), " as soon as possible."), "\n", React.createElement(_components.li, null, "Don't stand inside of AoEs, that strip your boons."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "mending",
    id: "14401"
  }), " when the Archdiviner is dead."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "3rd Phase (70%-50%)")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Follow the warrior guide."), "\n", React.createElement(_components.li, null, "At 50% before you leave for your bloom, cast ", React.createElement(Skill, {
    name: "battlestandard",
    id: "14419"
  }), " in the middle."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "4th Phase (50%-40%)")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "tremor",
    id: "14415"
  }), " to gain ", React.createElement(Item, {
    name: "severance",
    id: "84505"
  }), "."), "\n", React.createElement(_components.li, null, "Engage ", React.createElement(Skill, {
    name: "berserk",
    id: "30185"
  }), "."), "\n", React.createElement(_components.li, null, "Finish the phase with business as usual."), "\n", React.createElement(_components.li, null, "Pick up ", React.createElement(Skill, {
    name: "Banner of Strength",
    id: "14405"
  }), " and ", React.createElement(Skill, {
    name: "Banner of Discipline",
    id: "14407"
  }), " at the of the phase."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "Gladiator Phase")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Recast ", React.createElement(Skill, {
    name: "Banner of Strength",
    id: "14405"
  }), ", ", React.createElement(Skill, {
    name: "Banner of Discipline",
    id: "14407"
  }), " into a fire field for 6 Stacks of ", React.createElement(Boon, {
    name: "Might"
  })), "\n", React.createElement(_components.li, null, "Do what a ", React.createElement(Specialization, {
    name: "Warrior"
  }), " does."), "\n", React.createElement(_components.li, null, "When the mob dies, precast ", React.createElement(Skill, {
    name: "mending",
    id: "14401"
  }), "."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "5th Phase (40%-30%)")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Smash ", React.createElement(Skill, {
    name: "decapitate",
    id: "30851"
  }), " for as long as your ", React.createElement(Skill, {
    name: "berserk",
    id: "30185"
  }), " is still up."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "6th Phase (30%-0%)")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "tremor",
    id: "14415"
  }), " to gain ", React.createElement(Item, {
    name: "severance",
    id: "84505"
  }), "."), "\n", React.createElement(_components.li, null, "Engage ", React.createElement(Skill, {
    name: "berserk",
    id: "30185"
  }), "."), "\n", React.createElement(_components.li, null, "Finish the phase with business as usual."), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
